import React from 'react';

import * as styles from './index.module.css';

const TotalBadge = ({ total }) => {
  return (
    <div className={styles['root']}>
      <span className={styles['badge']}>{total}件</span>
    </div>
  );
};

export default TotalBadge;
